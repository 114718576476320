import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { MainService } from "../../services/main.service";
import { CommonService } from "../../services/common.service";

@Injectable({ providedIn: 'root' })
export class LabConnectionResolver implements Resolve<string[]> {

  constructor(private mainService: MainService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<string[]>|Promise<string[]>|string[] {
    const labId = route.paramMap.get('id') as string;
    return this.mainService.getLabWithId(labId)
  }
}