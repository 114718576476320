import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../environments/environment';
import { inject } from '@angular/core';
import { CommonService } from './labs/services/common.service';

export const httpClientInterceptor: HttpInterceptorFn = (req, next) => {
  const commonService = inject(CommonService);
  const token = commonService.getTokenFromUrl() || '';
  if (req.url.includes('assets')) {
    return next(req);
  }
  const isPreSignedUrl = req.url.startsWith('http://') || req.url.startsWith('https://');

  const tokenInParams = req.params.has('token');
  let params = req.params;
  if (token && !tokenInParams && !isPreSignedUrl) {
    params = params.set('token', token);
  }
  const apiReq = req.clone({
    url: isPreSignedUrl ? req.url : `${environment.apiBaseUrl}${req.url}`,
    params: params
  });
  return next(apiReq);
};
