import { Routes } from '@angular/router';
import { LabConnectionResolver } from './labs/shared/guards/labConnection.resolver';
import { StoppedLabConnectionResolver } from './labs/shared/guards/stoppedLabConnection.resolver';
import { ConfirmDeactivateGuard } from './labs/shared/guards/confirm-deactivate.guard';
import { AuthGuard } from './labs/shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo:'labs',
    pathMatch:'full'
  },
  {
    path: 'labs',
    loadComponent: () => import('./labs/components/connections/connections.component').then(c => c.ConnectionsComponent),
  },
  {
    path: 'add-lab',
    canActivate : [AuthGuard],
    loadComponent: () => import('./labs/components/add-lab/add-lab.component').then(c => c.AddLabComponent),
  },
  {
    path: 'add-lab/:id',
    canActivate : [AuthGuard],
    loadComponent: () => import('./labs/components/add-lab/add-lab.component').then(c => c.AddLabComponent),
    resolve: { labDetail: LabConnectionResolver },
  },
  {
    path: 'labs/:id/details',
    loadComponent: () => import('./labs/components/lab-detail/lab-detail.component').then(c => c.LabDetailComponent),
    canActivate : [AuthGuard],
    resolve: { labDetail: LabConnectionResolver },
  },
  {
    path: 'labs/:id/preview',
    loadComponent: () => import('./labs/components/lab-detail/lab-detail.component').then(c => c.LabDetailComponent),
    resolve: { labDetail: LabConnectionResolver },
  },
  {
    path: 'labs/:id/connection/:connectionid/details',
    loadComponent: () => import('./labs/components/lab-detail/lab-detail.component').then(c => c.LabDetailComponent),
    canActivate : [AuthGuard],
    resolve: { labDetail: StoppedLabConnectionResolver },
  },
  {
    path: 'labs/:id/connection/:connectionid',
    loadComponent: () => import('./labs/components/start-lab/start-lab.component').then(c => c.StartLabComponent),
    canActivate : [AuthGuard],
    canDeactivate: [ConfirmDeactivateGuard]
  },
  {
    path: '**',
    loadComponent: () => import('./labs/shared/four04/four04.component').then(c => c.Four04Component)
  }
];

