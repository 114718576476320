import { ApplicationConfig, isDevMode,importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { provideToastr } from 'ngx-toastr';
import { httpClientInterceptor } from './http-client.interceptor';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), 
    provideClientHydration(), 
    provideHttpClient(),
  importProvidersFrom([BrowserAnimationsModule]),
  provideAnimations(),
  provideToastr({
    timeOut: 5000,
    positionClass: 'toast-top-left',
    preventDuplicates: true,
    maxOpened: 0
  }),
  provideHttpClient(withInterceptors([httpClientInterceptor])),
  importProvidersFrom(NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }))
],
};
