import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { MainService } from "../../services/main.service";
import { CommonService } from "../../services/common.service";

@Injectable({ providedIn: 'root' })
export class StoppedLabConnectionResolver implements Resolve<string[]> {

  constructor(private mainService: MainService,private commonService:CommonService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<string[]>|Promise<string[]>|string[] {
    const labId = route.paramMap.get('id') as string;
    const connectionId = route.paramMap.get('connectionid') as string;
    return this.mainService.connectToStoppedLab(labId,connectionId)
  }
}